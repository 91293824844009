import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Select from 'react-select';
import { Tabs, Tab, MenuItem,FormControl, InputLabel , Card, CardContent, Typography, Grid, TextField, Box, List, ListItem } from '@mui/material';
import { format } from 'date-fns';


// Registramos los componentes de Chart.js que vamos a usar
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Estaciones = () => {
  const [data, setData] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState({ start: null, end: null });
  const [places, setPlaces] = useState([]);
  const [alarmSummary, setAlarmSummary] = useState({
    totalAlarmsByMonth: {},
    alarmsToday: 0,
    alarmsByDay: {},
    alarmsByPlace: {},
  });

  const [selectedTab, setSelectedTab] = useState(0);
 
  const handleTabChange = (event, newValue) => {
   setSelectedTab(newValue);
   
 };

  useEffect(() => {
    // Fetch de los datos desde el backend
    fetch("https://universidadvirtualdelperu.edu.pe/appiA2C/EstacionesBase")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        // Extraer lugares únicos para el filtro
        const uniquePlaces = [...new Set(data.map(item => item.place))];
        setPlaces(uniquePlaces.map(place => ({ value: place, label: place })));

        // Calcular resumen de alarmas
        const summary = calculateAlarmSummary(data);
        setAlarmSummary(summary);
      })
      .catch((error) => console.error('Error al obtener los datos:', error));
  }, []);

  // Función para obtener el día de la semana a partir de una fecha
  const getDayOfWeek = (date) => {
    const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const day = new Date(date).getDay();
    return daysOfWeek[day];
  };

  // Función para obtener el mes de una fecha
  const getMonth = (date) => {
    const monthNames = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    const month = new Date(date).getMonth();
    return monthNames[month];
  };

  // Función para obtener la fecha formateada
  const getFormattedDate = (date) => {
    return format(new Date(date), 'dd MMM yyyy');
  };

  // Función para calcular el resumen de alarmas
  const calculateAlarmSummary = (data) => {
    const alarmsByDay = {};
    const alarmsByMonth = {};
    const alarmsByPlace = {};
    let alarmsToday = 0;

    const today = new Date().toISOString().split('T')[0]; // Fecha de hoy en formato YYYY-MM-DD

    data.forEach(item => {
      const day = getDayOfWeek(item.date);
      const month = getMonth(item.date);
      const place = item.place;
      const date = item.date.split(' ')[0]; // Solo la parte de la fecha

      // Alarmas por Día
      alarmsByDay[day] = (alarmsByDay[day] || 0) + 1;

      // Alarmas por Mes
      alarmsByMonth[month] = (alarmsByMonth[month] || 0) + 1;

      // Alarmas por Lugar
      alarmsByPlace[place] = (alarmsByPlace[place] || 0) + 1;

      // Alarmas del Día Actual
      if (date === today) {
        alarmsToday++;
      }
    });

    return {
      totalAlarmsByMonth: alarmsByMonth,
      alarmsToday,
      alarmsByDay,
      alarmsByPlace,
    };
  };

  // Función para filtrar los datos según el lugar y el rango de fechas
  const filterData = () => {
    return data.filter(item => {
      const isInPlace = selectedPlace ? item.place === selectedPlace : true;
      const isInDateRange = selectedDateRange.start && selectedDateRange.end ? 
        new Date(item.date) >= new Date(selectedDateRange.start) && new Date(item.date) <= new Date(selectedDateRange.end) : true;
      return isInPlace && isInDateRange;
    });
  };
/////////////////////////////////////////// place vs Dia ////////////////////////
  // Preparar los datos para el gráfico de barras apiladas (Place vs Día de la semana) por nivel
const barChartData = () => {
  const filteredData = filterData();
  const dayCounts = {}; // Para almacenar los conteos por nivel dentro de cada día

  filteredData.forEach(item => {
    const day = getDayOfWeek(item.date); // Obtener el día de la semana
    const level = item.level?.toLowerCase(); // Obtener el nivel de la alarma (Critical, Major, Minor)

    if (!dayCounts[day]) {
      dayCounts[day] = { critical: 0, major: 0, minor: 0 };
    }

    // Contar las alarmas por nivel dentro de cada día
    if (level === "critical") {
      dayCounts[day].critical++;
    } else if (level === "major") {
      dayCounts[day].major++;
    } else if (level === "minor") {
      dayCounts[day].minor++;
    }
  });

  // Extraer las etiquetas (días) y los datasets
  const labels = Object.keys(dayCounts);
  const criticalData = labels.map((label) => dayCounts[label].critical || 0);
  const majorData = labels.map((label) => dayCounts[label].major || 0);
  const minorData = labels.map((label) => dayCounts[label].minor || 0);

  return {
    labels, // Días de la semana
    datasets: [
      {
        label: "Critical",
        data: criticalData,
        backgroundColor: "rgba(255, 99, 132, 0.8)",
      },
      {
        label: "Major",
        data: majorData,
        backgroundColor: "rgba(255, 159, 64, 0.8)",
      },
      {
        label: "Minor",
        data: minorData,
        backgroundColor: "rgba(75, 192, 192, 0.8)",
      },
    ],
  };
};

// Opciones para el gráfico de barras apiladas
const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      mode: 'index',
      intersect: false,
    },
  },
  scales: {
    x: {
      stacked: true, // Hacer apilado en el eje X
    },
    y: {
      stacked: true, // Hacer apilado en el eje Y
    },
  },
};

/////////////////////////////////////

  // Preparar los datos para el gráfico de líneas (Alarmas por Fecha)
  const lineChartData = () => {
    const filteredData = filterData();
    const dateCounts = filteredData.reduce((acc, item) => {
      const date = getFormattedDate(item.date); // Convertir la fecha a formato 'dd MMM yyyy'
      if (!acc[date]) acc[date] = 0;
      acc[date]++;
      return acc;
    }, {});

    return {
      labels: Object.keys(dateCounts),
      datasets: [
        {
          label: 'Alarmas por Fecha',
          data: Object.values(dateCounts),
          fill: false,
          borderColor: 'rgba(153, 102, 255, 1)',
          tension: 0.1,
        },
      ],
    };
  };

  // Preparar los datos para el gráfico de barras (Alarmas por Hora)
  const hourBarChartData = () => {
    const filteredData = filterData();
    const hourCounts = {};
    filteredData.forEach(item => {
      const hour = new Date(item.date).getHours(); // Obtener la hora de la fecha
      hourCounts[hour] = (hourCounts[hour] || 0) + 1;
    });

    return {
      labels: Array.from({ length: 24 }, (_, i) => `${i}:00`), // Etiquetas para las 24 horas
      datasets: [
        {
          label: 'Cantidad de Alarmas por Hora',
          data: Array.from({ length: 24 }, (_, i) => hourCounts[i] || 0), // Contar alarmas por hora
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1,
        },
      ],
    };
  };
///////////////////////////////////////////////////////////////////////////////////////

const [filter, setFilter] = useState('all'); // Estado para el filtro de lugares (CPE vs otros)


// Función para obtener el formato de fecha 'dd MMM yyyy'


// Función para filtrar los datos por el prefijo del lugar (CPE o no CPE)
const filterDataByPlacePrefix = (data, filter) => {
  if (filter === 'CPE') {
    return data.filter(item => item.place.startsWith('CPE'));
  } else if (filter === 'non-CPE') {
    return data.filter(item => !item.place.startsWith('CPE'));
  }
  return data;
};

  // Preparar los datos para el gráfico de líneas (Alarmas por Fecha por Place)
const lineChartDataByPlace = () => {
    const filteredData = filterDataByPlacePrefix(data, filter);
    const dateCountsByPlace = {};
  
    filteredData.forEach(item => {
      const date = getFormattedDate(item.date); // Convertir la fecha a formato 'dd MMM yyyy'
      const place = item.place;
  
      // Inicializar los datos para cada lugar
      if (!dateCountsByPlace[place]) {
        dateCountsByPlace[place] = {};
      }
  
      // Contar alarmas por lugar y fecha
      if (!dateCountsByPlace[place][date]) {
        dateCountsByPlace[place][date] = 0;
      }
      dateCountsByPlace[place][date]++;
    });
  
    // Generar los datos para el gráfico
    const labels = Object.keys(filteredData.reduce((acc, item) => {
      const date = getFormattedDate(item.date);
      acc[date] = true;
      return acc;
    }, {})); // Obtener todas las fechas únicas
  
    const datasets = Object.keys(dateCountsByPlace).map(place => {
      return {
        label: place,
        data: labels.map(date => dateCountsByPlace[place][date] || 0),
        fill: false,
        borderColor: getRandomColor(), // Genera un color aleatorio para cada lugar
        tension: 0.1,
      };
    });
  
    return {
      labels: labels,
      datasets: datasets,
    };
  };
  
  // Función para generar un color aleatorio para cada lugar
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  

  // Cambiar valor del filtro
  const handleFilterChange = (selectedOption) => {
    setFilter(selectedOption ? selectedOption.value : 'all');
  };

  // Función para preparar los datos del gráfico de barras: Nivel de la Base de Datos vs Cantidad de Alarmas
const levelBarChartData = () => {
    const filteredData = filterData();  // Filtra los datos según los filtros seleccionados
    const levelCounts = {};
  
    // Agrupar las alarmas por nivel
    filteredData.forEach(item => {
      const level = item.level;  // Suponiendo que 'level' es el campo que indica el nivel de la base de datos
      levelCounts[level] = (levelCounts[level] || 0) + 1;
    });
  
    return {
      labels: Object.keys(levelCounts),  // Los niveles de la base de datos
      datasets: [
        {
          label: 'Cantidad de Alarmas por Nivel',
          data: Object.values(levelCounts),  // La cantidad de alarmas para cada nivel
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };
  };

  ////////////////////////////////////////////////////////
  const processStackedBarData = () => {
    const filteredData = filterData();
    const groupedData = {};
  
    // Agrupar los datos por tipo y contar los niveles (Critical, Major, Minor)
    filteredData.forEach((item) => {
      const tipo = item.tipo; // Asegurarse de que se usa 'tipo' para la agrupación
      const level = item.level?.toLowerCase(); // Asegurarse de que el nivel está en minúsculas
  
      if (!groupedData[tipo]) {
        groupedData[tipo] = { critical: 0, major: 0, minor: 0 };
      }
  
      // Contar el número de elementos por nivel
      if (level === "critical") {
        groupedData[tipo].critical++;
      } else if (level === "major") {
        groupedData[tipo].major++;
      } else if (level === "minor") {
        groupedData[tipo].minor++;
      }
    });
  
    // Extraer las etiquetas (tipos) y los datasets
    const labels = Object.keys(groupedData);
    const criticalData = labels.map((label) => groupedData[label].critical || 0);
    const majorData = labels.map((label) => groupedData[label].major || 0);
    const minorData = labels.map((label) => groupedData[label].minor || 0);
  
    return {
      labels,
      datasets: [
        {
          label: "Critical",
          data: criticalData,
          backgroundColor: "rgba(255, 99, 132, 0.8)",
        },
        {
          label: "Major",
          data: majorData,
          backgroundColor: "rgba(255, 159, 64, 0.8)",
        },
        {
          label: "Minor",
          data: minorData,
          backgroundColor: "rgba(75, 192, 192, 0.8)",
        },
      ],
    };
  };

  const data2 = processStackedBarData();
////////////////////////////////////////////////////////////  

  
  
  return (
    <div style={{ padding: '30px', backgroundColor: '#f5f5f5' }}>
      <Typography variant="h4" gutterBottom>
        Estaciones Base
      </Typography>
      



      {/* Filtros */}
   
        <Box  sx={{
    // Bordes al lado derecho de las tabs
    display: 'flex',
    flexDirection: 'row', // Cambia la dirección de las tabs a vertical
    width:{xs:"100%", md:"100 %", lg:"100%"},
    gap:2,
    
  }}>

     <Box sx={{
    // Bordes al lado derecho de las tabs
    display: 'flex',
    flexDirection: 'column', // Cambia la dirección de las tabs a vertical
    width:{xs:"50%", md:"50 %", lg:"50%"},
    gap:2,
    
  }}>
        <Box >
          <Select
            options={places}
            value={selectedPlace ? { value: selectedPlace, label: selectedPlace } : null}
            onChange={(e) => setSelectedPlace(e ? e.value : null)}
            placeholder="Todos"
            isClearable
            InputLabelProps={{
                shrink: true, // Esto asegura que la etiqueta siempre se coloque encima del campo
              }}
          />
        </Box> 

        <Box sx={{
         // Bordes al lado derecho de las tabs
          display: 'flex',
          flexDirection: 'row', // Cambia la dirección de las tabs a vertical
          width:"100%",gap: 2, }}>
          <Box sx={{
    
           display: 'flex',
           width:"50%",}}>
           <TextField
             type="date"
             label="Desde"
             variant="outlined"
             fullWidth
             onChange={(e) => setSelectedDateRange({ ...selectedDateRange, start: e.target.value })}
             value={selectedDateRange.start || ''}
             InputLabelProps={{
                shrink: true, // Esto asegura que la etiqueta siempre se coloque encima del campo
              }}
            
            />
          </Box>
        
        <Box sx={{
            display: 'flex',
            width:"50%",}}>
          <TextField
            type="date"
            label="Hasta"
            variant="outlined"
            fullWidth
            onChange={(e) => setSelectedDateRange({ ...selectedDateRange, end: e.target.value })}
            value={selectedDateRange.end || ''}
            InputLabelProps={{
                shrink: true, // Esto asegura que la etiqueta siempre se coloque encima del campo
              }}
          />
        </Box>
        </Box>
        </Box>
        
        {/* Resumen de Alarmas */}
      
        <Box sx={{
            display: 'flex',
            width:"50%",}}>

       <Box sx={{
            display: 'flex',
            width:"50%",}}>

          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6">Alarmas Total por Mes</Typography>
              <Typography variant="h4">{Object.values(alarmSummary.totalAlarmsByMonth).reduce((a, b) => a + b, 0)}</Typography>
            </CardContent>
          </Card>
       </Box>
        
        <Grid item xs={6} sm={6} md={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6">Alarmas de {selectedPlace || "Todo"}</Typography>
              <Typography variant="h4">{alarmSummary.alarmsByPlace[selectedPlace] || Object.values(alarmSummary.totalAlarmsByMonth).reduce((a, b) => a + b, 0)}</Typography>
            </CardContent>
          </Card>
        </Grid>

      </Box>

        </Box>

      
      {/* Gráficos */}
      <Grid container spacing={4} style={{ marginTop: '20px' }}>
 
        <Grid item xs={12} md={8}>
          <Card variant="outlined">
            <CardContent>
            <Typography variant="h6" gutterBottom>
            Tendencia de Alarmas por Fecha de {selectedPlace || "--"}
              </Typography>
            
              <Line
  data={lineChartData()}
  options={{
    responsive: true,
    plugins: {
      legend: { display: false },
      title: {
        display: false,
        text: 'Tendencia de N. Alarmas por Fecha',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Fecha', // Título para el eje X
        },
      },
      y: {
        title: {
          display: true,
          text: 'Cantidad de Alarmas', // Título para el eje Y
        },
      },
    },
  }}
/>
</CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Últimas Alarmas de {selectedPlace || "--"}
              </Typography>
              <List>
                {filterData().slice(0, 5).map((item, index) => (
                  <ListItem key={index}>
                    <Typography>{item.place} - {item.date}</Typography>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

        {/* Últimas Alarmas */}
      <Grid container spacing={4} style={{ marginTop: '20px' }}>
        
      </Grid>

      {/* Gráfico de Alarmas por Hora */}
      <Grid container spacing={4} style={{ marginTop: '20px' }}>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Alarmas por Hora de {selectedPlace || "--"}
              </Typography>
              <Bar data={hourBarChartData()} options={{ responsive: true, plugins: { legend: { display: false }, title: { display: true, text: 'Alarmas por Hora' } , },scales: {
      x: {
        title: {
          display: true,
          text: 'Hora del día', // Título para el eje X
        },
      },
      y: {
        title: {
          display: true,
          text: 'Cantidad de Alarmas', // Título para el eje Y
        },
      },
    },
   }} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Alarmas por Día de la Semana de {selectedPlace || "--"}
              </Typography>
              <Bar data={barChartData()} options={{ responsive: true, plugins: { legend: { display: false }, title: { display: true, text: 'Alarmas por Día de la Semana' } },scales: {
      x: {
        title: {
          display: true,
          text: 'Día de la Semana', // Título para el eje X
        },
      },
      y: {
        title: {
          display: true,
          text: 'Cantidad de Alarmas', // Título para el eje Y
        },
      },
    }, }} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={4} style={{ marginTop: '20px' }}>
  <Grid item xs={12} md={6}>
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Alarmas por Nivel de la Base de Datos de {selectedPlace || "--"}
        </Typography>
        <Bar
          data={levelBarChartData()}  // Llama a la función que prepara los datos
          options={{
            responsive: true,
            plugins: {
              legend: { display: false },
              title: {
                display: true,
                text: 'Alarmas por Nivel de la Base de Datos',
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Nivel de la Base de Datos',  // Título para el eje X
                },
              },
              y: {
                title: {
                  display: true,
                  text: 'Cantidad de Alarmas',  // Título para el eje Y
                },
              },
            },
          }}
        />
      </CardContent>
    </Card>
  </Grid>
</Grid>

<Typography variant="h6">Gráfica de Barras Apiladas: Cantidad de Alarmas por Tipo y Nivel</Typography>
<Bar
      data={data2}
      options={{
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'Gráfico de Niveles por Tipo',
          },
          legend: {
            position: 'top',
          },
        },
        scales: {
          x: {
            stacked: true, // Habilitar apilamiento en el eje X
          },
          y: {
            stacked: true, // Habilitar apilamiento en el eje Y
          },
        },
      }}
    />
    </div>



  );
};

export default Estaciones;
